.theGrid{
  position: relative; 
  width: auto;
  height: 290px;
  color: #ECF3F8;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container,
.spring,
.river,
.frost,
.aboutus,
.summer {
  display: block;
  width: 100%;
  overflow: hidden;
}

.front{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;  
  flex-direction: column;
  align-items: center;
  justify-content: center;    
  padding: 30px 20px;
  transition: all 0.2s ease-out;
}

.slide{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px; 
  opacity: 0;
  transition: all 0.2s ease-out;
}
  
.front .img {
  width: 50px;
  height: 50px;
  animation: animation 9s infinite;
  transform: scale(1);
}

.title {
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}

.slide .img{
  color: #012310;
  width: 30px;
  height: 30px;
}

.slide .title{
  color: #012310;
  font-size: 14px;
  line-height: 120%;
  margin-top: 5px;
}

.description{
  color: #012310;
  margin-top: 5px;
  font-size: 12px;
  line-height: 120%;
} 

.theGrid.hovered .slide,
.theGrid:focus .slide{
  color: #012310;
  opacity: 1;
  transition: all 0.2s ease-out;
}

.theGrid.hovered .front,
.theGrid:focus .front{
  opacity: 0;
  transition: all 0.2s ease-out;
}

.spring .img,
.spring .theGrid,
.frost .img,
.frost .theGrid,
.summer .img,
.summer .theGrid{
  color: #012310;  
}

.aboutus .img,
.aboutus .theGrid{
  color: currentColor;
}

@keyframes animation {
  5% {
    transform: scale(1.1);
  }

  10% {
    transform: scale(1);
  }

  15% {
    transform: scale(1.1);
  }

  20% {
    transform: scale(1);
  }
}

@media (min-width: 480px){
  .front, 
  .slide{
    padding: 30px 30px;
  }

  .description{
    font-size: 14px;
    margin-top: 15px;
  }

  .title{
    font-size: 18px;
  }    
}

@media (min-width: 768px){
  .slide{
    padding: 10px;
  }
  .description{
    margin-top: 10px;
    font-size: 13px;
  }

    li:nth-child(2n).river {
    background-color:#152A3D;        
  }
}

@media (min-width: 1200px){
  .title {
    font-size: 18px;
  }
  .slide{
    padding: 20px;
  }
  .blueberry .slide{
    padding: 10px;
  }
  .description{
    font-size: 14px;
    line-height: 140%;
  }
}

@media (min-width: 1400px){
  .front, 
  .slide{
    padding: 0px 40px;
  }

  .blueberry .slide{
    padding: 0px 20px;
  }

  .title {
    font-size: 18px;
  }

  .container{
    height: 350px;
  }

  .description{
    font-size: 15px;
  }
}

li:nth-child(1).river {
  background-color:#20564E;
  transition: all 0.2s ease-out;
}

li:nth-child(2n).river {
  background-color:#152A3D;
  transition: all 0.2s ease-out;
}

li:nth-child(3).river {
  background-color:#2A537A;
  transition: all 0.2s ease-out;
}

li:nth-child(1).river.hovered,
li:nth-child(1).river.hovered {
  background-color:#E6FEF1;
  transition: all 0.2s ease-out;
}

li:nth-child(2n).river.hovered,
li:nth-child(2n).river.hovered {
  background-color: #D5E4F1;
  transition: all 0.2s ease-out;
}

li:nth-child(3).river.hovered,
li:nth-child(3).river.hovered {
    background-color: #E4EEF6;
    transition: all 0.2s ease-out;
}

/* for spring
----------------------------------------------------
------------------------------------------------------------------------------------- */
li:nth-child(1).spring{
  border: 2px solid;
  border-color: transparent;
  background-color:#DCFEEB;
  transition: all 0.2s ease-out;
}

li:nth-child(2n).spring{
  border: 2px solid;
  border-color: transparent;
  background-color:#A6FDCC;
  transition: all 0.2s ease-out;
}

li:nth-child(3).spring{
  border: 2px solid;
  border-color: transparent;
  background-color:#DCFEEB;
  transition: all 0.2s ease-out;
}

li:nth-child(1n).spring.hovered,
li:nth-child(1n).spring.hovered{
  background-color:#FFFFFF;
  border: 2px solid #A6FDCC;
  transition: all 0.2s ease-out;
}

/* for frost ----------------------------------------------------
-------------------------------------------------------------------------------------*/
li:nth-child(1).frost {
  border: 1px solid;
  border-color: transparent;
  background-color:#D6F0EC;
  transition: all 0.2s ease-out;
}

li:nth-child(2n).frost {
  border-top: 1px solid;
  border-color: #20564E;
  background-color:#D6F0EC;
  transition: all 0.2s ease-out;
}

li:nth-child(3).frost {
  border-top: 1px solid;
  border-color: #20564E;
  background-color:#D6F0EC;
  transition: all 0.2s ease-out;
}
  
li.frost.hovered,
li.frost.hovered{
  background-color:#F5FFF9;
  transition: all 0.2s ease-out;
}

@media (min-width: 768px)  {
  li:nth-child(1).frost {  
    border: none;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: #20564E;
  }

  li:nth-child(2).frost{
    border: none;
    border-bottom: 1px solid;
  }

  li:nth-child(3).frost{
    border: none;
  }

  li:nth-child(4).frost{
    border: none;
    border-right: 1px solid;
    border-color: #20564E;
  }
}  

/* * for  blueberry ----------------------------------------------------
-------------------------------------------------------------------------------------*/
li:nth-child(1n).blueberry {
  background-color:#2A537A;
  transition: all 0.2s ease-out;
}

li:nth-child(1n).blueberry.hovered ,
li:nth-child(1n).blueberry.hovered {
  background-color:#FFFFFF;
  transition: all 0.2s ease-out;
}

.cardContainer.blueberry.hovered,
.cardContainer.blueberry.hovered{
  border: 1px solid #2A537A;
  transition: all 0.2s ease-out;
}
.blueberry .title{
  margin: 0;
}


/* for aboutus
----------------------------------------------------
------------------------------------------------------------------------------------- */
li:nth-child(1).aboutus {
  border: 2px solid;
  border-color: transparent;
  background-color:#E4EEF6;
  transition: all 0.2s ease-out;
}

li:nth-child(2n).aboutus {
  border: 2px solid;
  border-color: transparent;
  background-color:#2A537A;
  transition: all 0.2s ease-out;
}

li:nth-child(3).aboutus {
  border: 2px solid;
  border-color: transparent;
  background-color:#E4EEF6;
  transition: all 0.2s ease-out;
}

li:nth-child(2).aboutus ,
li:nth-child(4).aboutus {
  color: #F5FFF9;
}

li:nth-child(1n).aboutus.hovered ,
li:nth-child(1n).aboutus.hovered {
  background-color:#FFFFFF;
  border: 2px solid #2A537A;
  transition: all 0.2s ease-out;
}

@media (min-width: 768px)  {
  li:nth-child(3).aboutus {
    background-color:#2A537A;
  }

  li:nth-child(4).aboutus {
    background-color: #E4EEF6;
  }

  li:nth-child(2).aboutus ,
  li:nth-child(3).aboutus {
    color: #F5FFF9;
  }

  li:nth-child(4).aboutus {
    color: #012310;;
  }
} 

/* for summer ----------------------------------------------------
-------------------------------------------------------------------------------------*/
li:nth-child(1).summer{
  border: 1px solid;
  border-color: transparent;
  background-color:#FFF6D6;
  transition: all 0.2s ease-out;
}

li:nth-child(2n).summer {
  border-top: 1px solid;
  border-top-color: #E3CF8E;
  background-color:#FFF6D6;
  transition: all 0.2s ease-out;
}

li:nth-child(3).summer {
  border-top: 1px solid;
  border-color: #E3CF8E;
  background-color:#FFF6D6;
  transition: all 0.2s ease-out;
}
  
li.summer.hovered,
li.summer.hovered{
  background-color:#fffef8;
  transition: all 0.2s ease-out;
}

@media (min-width: 768px)  {
  li:nth-child(1).summer {  
    border: none;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: #E3CF8E;
  }

  li:nth-child(2).summer{
    border: none;
    border-bottom: 1px solid #E3CF8E;
  }

  li:nth-child(3).summer{
    border: none;
  }

  li:nth-child(4).summer{
    border: none;
    border-right: 1px solid;
    border-color: #E3CF8E;
  }
}  

.gridSection{
  width: auto;
  background-color: #ECF3F8
}

.river{
  width: auto;
  background-color: #ECF3F8
}

.title{
  text-align: center;
  /* margin-bottom: 0; */
}

.btnWrapper {
  margin-top: 40px;
}
.river .title{
  margin: 0;
}

.cardContainer{
  display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    width: 100%;
    margin-top: 35px;
}

@media (min-width: 768px)  {
  .cardContainer{
    grid-template-columns: repeat(2, 1fr);
    max-width: 100%;
    margin-top: 45px;
  }

  .river li:nth-child(4),
  .spring li:nth-child(4),
  .frost li:nth-child(4), 
  .summer li:nth-child(4) {
    grid-column-start: 1; 
    grid-row-start: 2;
  }
}   

@media (min-width: 1200px) {
  .cardContainer{
    margin-top: 65px;
  }

  .blueberry li:nth-child(6){
    grid-column-start: 1; 
    grid-row-start: 2;
  }
  .blueberry li:nth-child(4){
    grid-column-start: 3; 
    grid-row-start: 2;
  }
}

@media (min-width: 1920px) {
}

/* for spring
----------------------------------------------------
------------------------------------------------------------------------------------- */
.spring{ 
  background-color: #F5FFF9;
}

/* for frost ----------------------------------------------------
-------------------------------------------------------------------------------------*/
.frost{
  background-color: #BCE6E0;
}

/* for aboutus
----------------------------------------------------
------------------------------------------------------------------------------------- */
.aboutus{ 
  background-color: #BBD2E7;
}

.aboutus li:nth-child(2n){
  border: 2px solid;
  border-color: transparent;
  background-color:#2A537A;
  transition: all 1s ease-out;
}

.aboutus li:nth-child(3){
  border: 2px solid;
  border-color: transparent;
  background-color:#E4EEF6;
  color: #012310;
  transition: all 1s ease-out;
}

.aboutus li:nth-child(4){
  color: #F5FFF9;
}

/* for blueberry
----------------------------------------------------
------------------------------------------------------------------------------------- */
.blueberry { 
  background-color: #ECF3F8;
}

.blueberry .cardContainer{
  border: 1px solid transparent;
  background-color: #ECF3F8;
  gap: 1px;
  box-sizing: content-box;
  transition: all 1s ease-out;
}

@media (min-width: 1200px)  {
  .blueberry .cardContainer{
    grid-template-columns: repeat(3, 1fr);
  }
}

/* for summer
----------------------------------------------------
------------------------------------------------------------------------------------- */
.summer { 
  background-color: #FFFBEE;
}

.summer .cardContainer{
  border: 1px solid transparent;
  /* background-color: #E3CF8E; */
  gap: 1px;
  /* box-sizing: content-box; */
  transition: all 1s ease-out;
}